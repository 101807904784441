import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import url from './Url';

class ExpertList extends Component {
  state = {
    ExpertData: [],
    expert: '',
    baseExpertUrl: '/expert/',
    intoductionText: ''
  }

  componentDidMount() {
    document.title = "Experts - Museum of Philately"

    if(this.props.SubsiteSetting.id){
      document.title = `Experts - ${this.props.SubsiteSetting.title} - Museum of Philately`
      this.setState({
          baseExpertUrl: '/subsites/'+this.props.SubsiteSetting.slug+'/expert/',
      });
    }

    this.setState({ intoductionText: this.props.GlobalsData['experts'] })

    const ExpertData = this.props.ExpertData;
    this.setState({ ExpertData });
  }

  handleChangeExpert = event => {
    this.setState({ expert: event.target.value })
  }

  render() {
    const ExpertData = this.state.ExpertData
    const expert = this.state.expert
    const filterDropdown = ExpertData.filter(function(result){
      return result.name.toLowerCase().includes(expert.toLowerCase());
    })

    return (
      <div className="container pt-3 pt-md-5 mb-5">
        <div className="row mb-3 mb-md-5">
          <div className="col text-center">
            <h2 className="text-primary font-weight-normal">Experts</h2>
            <p dangerouslySetInnerHTML={{ __html: this.state.intoductionText }} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <form className="form-inline justify-content-center mb-5">
              <div className="form-group">
                <input onChange={this.handleChangeExpert} type="text" className="form-control form-control-sm" id="searchExpert" placeholder="Search for an expert"></input>
            </div>
            </form>
          </div>
          </div>
          <div className="row">
            {filterDropdown.map((expertDetail) =>
              <div className="col-6 col-md-3 py-3" key={expertDetail.idcollector}>
                <div className="row">
                  <div className="col">
                    <Link to={this.state.baseExpertUrl+expertDetail.idcollector}>
                      <img className="collector-rounded-thumbnail img-thumbnail rounded-circle" src={url.url + expertDetail.photo.medium} alt={'Picture of ' + expertDetail.name}>
                      </img>
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p>
                      <Link to={this.state.baseExpertUrl+expertDetail.idcollector} className="text-primary">
                        {expertDetail.name}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
    );
  }
}

export default ExpertList;
