import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import url from './Url';


class ExpertSingle extends Component {
    state = {
        ExpertPhoto: '',
        ExpertName: '',
        ExpertFullName: '',
        ExpertBio: '',
        ExpertCertificatesLength: '',
        ExpertCertificates: [],
        baseRarityUrl: '/rarity/',
      }

      componentDidMount() {

        console.log('test');
            const ExpertData = this.props.ExpertData;
            const idcollector = ExpertData.findIndex(x => x.idcollector === parseInt(this.props.match.params.id));

            const ExpertPhoto = ExpertData[idcollector].photo.medium
            const ExpertName = ExpertData[idcollector].name
            const ExpertFullName = ExpertData[idcollector].fullname
            const ExpertBio = ExpertData[idcollector].description
            const ExpertCertificates= ExpertData[idcollector].certificates
            const ExpertCertificatesLength = ExpertData[idcollector].certificates.length

            document.title = `${ExpertName} - Museum of Philately`

            if(this.props.SubsiteSetting.id){
                document.title = `${ExpertName} - ${this.props.SubsiteSetting.title} - Museum of Philately`
                this.setState({
                    baseRarityUrl: '/subsites/'+this.props.SubsiteSetting.slug+'/rarity/'
                });
            }


            this.setState({ ExpertFullName, ExpertPhoto, ExpertName, ExpertBio, ExpertCertificatesLength, ExpertCertificates });

      }

      Certificates() {

          if (this.state.ExpertCertificates.length > 0) {
              return (
                      <div className="col-md-6 col-12 bg-light py-4 px-4 mb-3">
                          <h3 className="h3-small text-secondary font-weight-normal pb-3">Certificates</h3>
                          {this.state.ExpertCertificates.map((rarityCertificates) =>
                              <div className="row  pb-3" key={rarityCertificates.idcertificate}>
                                  <div className="col-3">
                                      <a href={url.url + rarityCertificates.certificate_file.file}>
                                          <img className="img-fluid" src={url.url + rarityCertificates.certificate_file.medium} alt="Certificate">
                                          </img>
                                      </a>
                                  </div>
                                  <div className="col-9">
                                      <Link to={this.state.baseRarityUrl+rarityCertificates.item.id}>
                                          <p className="text-primary mb-0 text-left">{rarityCertificates.item.name}</p>
                                      </Link>
                                      <a href={url.url + rarityCertificates.certificate_file.file}>
                                          <p className="text-primary mb-0 text-left">{rarityCertificates.certificate_number}</p>
                                      </a>
                                      <p className="text-info text-left">{rarityCertificates.certificate_date}</p>
                                  </div>
                              </div>

                          )}
                      </div>
              )
          }
          return null;

      }

    render() {
        return (
            <div className="container pt-3 pt-md-5 mb-5">
                <div className="row">
                  <div className="col-4 offset-4 col-md-2 offset-md-5 pb-3">
                      <img className="img-fluid" src={url.url + this.state.ExpertPhoto} alt={'Picture of ' + this.state.ExpertName}>
                      </img>
                  </div>
                </div>
                <div className="row mb-3 mb-md-5">
                    <div className="col text-center">
                        <h2 className="text-primary font-weight-normal">{this.state.ExpertFullName}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-12 text-left py-4 px-5">
                        <h3 className="h3-small text-secondary font-weight-normal pb-3">Biography</h3>
                        <p className="text-secondary">{this.state.ExpertBio}</p>
                    </div>
                    {this.Certificates()}
                </div>
            </div>
        );
    }
}

export default ExpertSingle;
