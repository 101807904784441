import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import url from './Url';
import banner from '../img/Exhibitions_Banner_image.jpg';
import stampex from '../img/stampex_logo.jpeg'


class ExhibitionList extends Component {
  state = {
    posts: []
  }

  componentDidMount() {

    document.title = "Exhibitions - Museum of Philately"

    const posts = this.props.CollectionData;
    this.setState({ posts });
  }

  render() {
    return (
      <>
        <div className="container-fluid pt-3 pb-5 pt-md-5 index-container bg-header-top position-relative" style={{ backgroundImage: `url(${banner})` }}>
          <img className="img-fluid" id="stampex" src={stampex} alt="Stampex 2020 logo"></img>
          <div className="row">
            <div className="col-10 offset-1 col-xl-6 offset-xl-3 bg-primary-transparent mt-5 py-5 px-0">
              <p className="text-uppercase index-sub px-5">Stampex 2020</p>
              <h1 className="index-title text-left">Court of Honour</h1>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col">
              <a href="https://stampex.vfairs.com/" rel="noopener noreferrer" target="_blank" role="button" className="btn btn-secondary mx-2"><strong>Visit Stampex 2020 website</strong></a>
            </div>
          </div>
        </div>
        <div className="container pt-5 mb-5">
          <div className="row">
            <div className="col col-lg-6 offset-lg-3">
              <p className="text-info border border-info p-4 mb-5">Court of Honour is a world class Virtual Exhibition excusively available during Virtual Stampex from 1st to 3rd October 2020, and hosted by the 'Museum of Philately' to celebrate a selection of world leading collectors and their collections.</p>
            </div>
          </div>
          {this.state.posts.map((collectionDetail) => {
            if (collectionDetail.type === 'exhibition') {
              return (
                <div className="row align-items-center py-3 border-bottom" key={collectionDetail.idcollection}>
                  <div className="col-2 offset-1">
                    <Link to={`/collection/${collectionDetail.idcollection}`}>
                      <img className="img-fluid collection-thumbnail" src={url.url + collectionDetail.photo.medium} alt={'Picture of ' + collectionDetail.name}>
                      </img>
                    </Link>
                  </div>
                  <div className="col-8 text-left">
                    <Link to={`/collection/${collectionDetail.idcollection}`}>
                      <h2 className="text-primary font-weight-normal h2-small">{collectionDetail.name}</h2>
                    </Link>
                    <p>
                      <Link to={`/collector/${collectionDetail.collector.idcollector}`}>
                        <span className="text-secondary">{collectionDetail.collector.name}</span>
                      </Link>
                      <span className="float-right text-info">{collectionDetail.pages.length + ' pages'}</span>
                    </p>
                  </div>
                </div>
              )
            }
            return null;
          }
          )}
        </div>
      </>
    );
  }
}
export default ExhibitionList;
