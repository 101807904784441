import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import url from './Url';
import noflag from '../img/noflag.png';




class CountryList extends Component {

  state = {
    CountryData: [],
    country: ''

  }

  componentDidMount() {
    document.title = "Countries - Museum of Philately"


    const CountryData = this.props.CountryData;
    this.setState({ CountryData });
  }

  handleChangeCountry = event => {
    this.setState({ country: event.target.value })
  }

  render() {
    const CountryData = this.state.CountryData
    const country = this.state.country
    const filterDropdown = CountryData.filter(function (result) {
      return result.name.toLowerCase().includes(country.toLowerCase());
    })

    return (
      <div className="container pt-3 pt-md-5 mb-5">
        <div className="row mb-3 mb-md-5">
          <div className="col text-center">
            <h2 className="text-primary font-weight-normal">Countries</h2>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <form className="form-inline justify-content-center mb-5">
              <div className="form-group">
                <input onChange={this.handleChangeCountry} type="text" className="form-control form-control-sm" id="searchCountry" placeholder="Search for a country"></input>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          {filterDropdown.map((countryDetail, index) => {
            if (countryDetail.photo != null) {
              return (
                <div className="col-6 col-md-3 py-3" key={index}>
                  <div className="row">
                    <div className="col">
                      <Link to={`/country/${index}`}>
                        <img className="collector-rounded-thumbnail img-thumbnail rounded-circle" src={url.url + countryDetail.photo.medium} alt={countryDetail.name + 'flag'}>
                        </img>
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>
                        <Link to={`/country/${index}`} className="text-primary">
                          {countryDetail.name}
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              )
            }
            return (
              <div className="col-6 col-md-3 py-3" key={index}>
                <div className="row">
                  <div className="col">
                    <Link to={`/country/${index}`}>
                      <img className="collector-rounded-thumbnail img-thumbnail rounded-circle" src={noflag} alt={'Missing flag'}>
                      </img>
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p>
                      <Link to={`/country/${index}`} className="text-primary">
                        {countryDetail.name}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>

            )
          }

          )
          }
        </div>
      </div >
    );
  }
}
export default CountryList;
