import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import url from './Url';
import noimage from '../img/noimage.png';


class CollectorSingle extends Component {
    state = {
        CollectorPhoto: '',
        CollectorName: '',
        CollectorFullName: '',
        CollectorBio: '',
        CollectorCollectionLength: '',
        CollectorCollections: [],
        CollectorProvenances: [],
        CollectorBibliography: [],
        baseCollectionUrl: '/collection/',
        baseCollectorUrl: '/collector/',
        baseRarityUrl: '/rarity/',
      }

      componentDidMount() {

            const CollectorData = this.props.CollectorData;
            const idcollector = CollectorData.findIndex(x => x.idcollector === parseInt(this.props.match.params.id));

            const CollectorPhoto = CollectorData[idcollector].photo.medium
            const CollectorName = CollectorData[idcollector].name
            const CollectorFullName = CollectorData[idcollector].fullname
            const CollectorBio = CollectorData[idcollector].description
            const CollectorCollections = CollectorData[idcollector].collections
            const CollectorProvenances = CollectorData[idcollector].provenances
            const CollectorCollectionLength = CollectorData[idcollector].collections.length
            const CollectorBibliography = CollectorData[idcollector].bibliography

            document.title = `${CollectorName} - Museum of Philately`

            if(this.props.SubsiteSetting.id){
                document.title = `${CollectorName} - ${this.props.SubsiteSetting.title} - Museum of Philately`
                this.setState({
                    baseCollectionUrl: '/subsites/'+this.props.SubsiteSetting.slug+'/collection/',
                    baseCollectorUrl: '/subsites/'+this.props.SubsiteSetting.slug+'/collector/',
                    baseRarityUrl: '/subsites/'+this.props.SubsiteSetting.slug+'/rarity/'
                });
            }

            this.setState({ CollectorFullName, CollectorPhoto, CollectorName, CollectorBio, CollectorCollectionLength, CollectorProvenances, CollectorCollections, CollectorBibliography });

      }

      Collections() {
        if (this.state.CollectorCollectionLength > 0) {
            return (
            <div className="text-left bg-light py-4 px-5">
                <h3 className="h3-small text-secondary font-weight-normal pb-3">Collections</h3>
                {this.state.CollectorCollections.map((collectorCollections, index) =>
                    <div className={`row align-items-center border-bottom pb-3 ${index !== 0 ? 'pt-3' : ''}`} key={collectorCollections.id}>
                        <div className="col-2">
                            <Link to={this.state.baseCollectionUrl + collectorCollections.id}>
                                <img className="img-fluid" src={collectorCollections.photo ? url.url + collectorCollections.photo.medium : noimage} alt={'Picture of ' + collectorCollections.name}>
                                </img>
                            </Link>
                        </div>
                        <div className="col-10 text-info">
                            <Link to={this.state.baseCollectionUrl + collectorCollections.id} className="text-primary">
                                {collectorCollections.name}
                            </Link>
                        </div>
                    </div>
                )}
            </div>
            )
        }
          return null;
      }

      Provenances() {
        console.log(this.state.CollectorProvenances);
          if (this.state.CollectorProvenances.length>0) {
              return (
                <div className="text-left bg-light py-4 px-5 mb-3">
                    <h3 className="h3-small text-secondary font-weight-normal pb-3">Rarities</h3>
                    {this.state.CollectorProvenances.map((collectorProvenance) =>
                        <div className="row align-items-center border-bottom pb-3" key={collectorProvenance.idprovenance}>
                            <div className="col-2">
                                <Link to={this.state.baseRarityUrl+collectorProvenance.item.iditem}>
                                    <img className="img-fluid" src={url.url + collectorProvenance.item.photo_front.medium} alt={'Picture of ' + collectorProvenance.item.name}>
                                    </img>
                                </Link>
                            </div>
                            <div className="col-10 text-info">
                                <Link to={this.state.baseRarityUrl+collectorProvenance.item.iditem} className="text-primary">
                                    {collectorProvenance.item.name}
                                </Link>
                                <p>{collectorProvenance.item.subtitle}</p>
                            </div>
                        </div>
                    )}
                </div>
              )
          }
          return null;
      }

      Bibliography() {

          if (this.state.CollectorBibliography.length > 0) {
              return (
                      <div className="text-left  bg-light py-4 px-5">
                          <h3 className="h3-small text-secondary font-weight-normal pb-3">Bibliography</h3>
                          {this.state.CollectorBibliography.map((collectorBibliography) => {
                              if (this.state.CollectorBibliography.link !== "") {
                                  return (
                                      <a href={collectorBibliography.link}>
                                          <p className="text-primary mb-0">{collectorBibliography.name}</p>
                                          <p className="text-info mb-0">{collectorBibliography.author}</p>
                                          <p className="text-secondary">{collectorBibliography.remarks}</p>
                                      </a>
                                  )
                              }
                              return (
                                  <div>
                                      <p className="text-primary mb-0">{collectorBibliography.name}</p>
                                      <p className="text-info mb-0">{collectorBibliography.author}</p>
                                      <p className="text-secondary">{collectorBibliography.remarks}</p>
                                  </div>
                              )
                          }
                          )}
                      </div>
              )
          }
          return null;

      }

    render() {
        return (
            <div className="container pt-3 pt-md-5 mb-5">
                <div className="row">
                <div className="col-4 offset-4 col-md-2 offset-md-5 pb-3">
                        <img className="img-fluid" src={url.url + this.state.CollectorPhoto} alt={'Picture of ' + this.state.CollectorName}>
                        </img>
                    </div>
                </div>
                <div className="row mb-3 mb-md-5">
                    <div className="col text-center">
                        <h2 className="text-primary font-weight-normal">{this.state.CollectorFullName}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-12 text-left py-4 px-5">
                        <h3 className="h3-small text-secondary font-weight-normal pb-3">Biography</h3>
                        <p className="text-secondary">{this.state.CollectorBio}</p>
                    </div>
                    <div className="col-md-6 offset-md-0 col-10 offset-1">
                      {this.Collections()}
                      {this.Provenances()}
                      {this.Bibliography()}
                    </div>
                </div>
            </div>
        );
    }
}

export default CollectorSingle;
