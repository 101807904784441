import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import banner from '../img/Banner-MoP-2021.jpg';
import bg from '../img/art-gallery-55.jpg';
import stampex from '../img/stampex_logo.jpeg';
import yt from '../img/image_video_MoP.jpg';
import AnchorLink from 'react-anchor-link-smooth-scroll';




class index extends Component {
    state = {
      LatestBlogData: []
    }
    componentDidMount() {
        document.title = "Museum of Philately"
        axios.get(`https://blog.museumofphilately.com/wp-json/wp/v2/posts?per_page=1&_embed`)
          .then(res => {
            const LatestBlogData = res.data[0];
            this.setState({ LatestBlogData });
          });
    }
    LatestBlog() {
        if (this.state.LatestBlogData.title) {
            var imageUrl=stampex;
            if(this.state.LatestBlogData._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url){
              imageUrl=this.state.LatestBlogData._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url;
            }
            return (
                <div className="row mb-5 pb-3">
                    <div className="col-10 offset-1 col-lg-4 offset-lg-2 mr-lg-n3 p-0">
                        <img className="img-fluid shadow-lg" src={imageUrl} alt="Stampex logo" />
                    </div>
                    <div className="col-8 offset-2 col-lg-4 mt-n2 my-lg-5 p-4 ml-lg-n3 shadow-lg text-left bg-white">
                        <h2 className="index-small-title" dangerouslySetInnerHTML={{__html: this.state.LatestBlogData.title.rendered}} />
                        <p dangerouslySetInnerHTML={{__html: this.state.LatestBlogData.excerpt.rendered}} />
                        <a href={this.state.LatestBlogData.link} target="_blank" className="black-links" >Read more</a>
                    </div>
                </div>
            )
        }
        return null;

    }
    render() {
        return (
            <div className="bg-main">
                <div className="container-fluid py-5 index-container bg-header-top" style={{ backgroundImage: `url(${banner})` }}>
                    <div className="row">
                        <div className="col-10 offset-1 col-xl-6 offset-xl-3 bg-primary-transparent mt-5 py-5 px-0">
                            <p className="text-uppercase index-sub px-5">Keep your collection alive</p>
                            <h1 className="index-title text-left">Museum of Philately</h1>
                        </div>
                    </div>
                    <div className="row pt-5">
                        <div className="col">
                            <Link to={'/collections'} role="button" className="btn btn-secondary mx-2"><strong>View the collections</strong></Link>
                            <a href="http://eepurl.com/hqJU9n" role="button" className="btn btn-secondary mx-2" target="_blank"><strong>Subscribe to the newsletter</strong></a>
                        </div>
                    </div>
                </div>
                <div className="container py-5" style={{ backgroundImage: `url(${bg})` }}>
                    {this.LatestBlog()}
                    <div className="row">
                        <div className="col-8 offset-2 col-lg-4 mb-n2 my-lg-5 p-4 mr-lg-n3 shadow-lg text-left bg-white z-fix">
                            <h2 className="index-small-title">Discover the museum</h2>
                            <p>Learn more about the Museum of Philately, in a short presentation narrated by an exhibitor.</p>
                            <a className="black-links" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=zfTJfe0f3Ho&feature=youtu.be">Play video</a>
                        </div>
                        <div className="col-10 offset-1 col-lg-4 offset-lg-2 ml-lg-n3 p-0">
                            <a className="black-links" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=zfTJfe0f3Ho&feature=youtu.be">
                                <img className="img-fluid shadow-lg" src={yt} alt="Youtube preview" /></a>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default index;
