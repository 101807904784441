import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import url from './Url';

class RaritySingle extends Component {


    state = {
        RarityName: '',
        RarityPhotoFront: '',
        RarityDescription: '',
        RarityCertificates: [],
        RarityEstimate: null,
        RarityCollection: null,
        RarityProvenances: [],
        RarityBibliography: [],
        baseCollectionUrl: '/collection/',
        baseCollectorUrl: '/collector/',
        baseRarityUrl: '/rarity/',
    }

    componentDidMount() {

        const RarityData = this.props.RarityData;
        const iditem = RarityData.findIndex(x => x.iditem === parseInt(this.props.match.params.id));

        const RarityName = RarityData[iditem].name
        const RarityPhotoFront = RarityData[iditem].photo_front.file
        let RarityPhotoBack = null
        if(RarityData[iditem].photo_back){
            RarityPhotoBack = RarityData[iditem].photo_back.file
        }
        const RarityDescription = RarityData[iditem].description
        const RarityCertificates = RarityData[iditem].certificates
        const RarityEstimate = RarityData[iditem].estimate
        const RarityEstimateLastDate = RarityData[iditem].date
        const RarityProvenances = RarityData[iditem].provenances
        const RarityCollection = RarityData[iditem].collection
        const RarityBibliography = RarityData[iditem].bibliography

        document.title = `${RarityName} - Museum of Philately`

        if(this.props.SubsiteSetting.id){
            document.title = `${RarityName} - ${this.props.SubsiteSetting.title} - Museum of Philately`
            this.setState({
                baseCollectionUrl: '/subsites/'+this.props.SubsiteSetting.slug+'/collection/',
                baseCollectorUrl: '/subsites/'+this.props.SubsiteSetting.slug+'/collector/',
                baseRarityUrl: '/subsites/'+this.props.SubsiteSetting.slug+'/rarity/'
            });
        }


        this.setState({ RarityPhotoBack, RarityBibliography, RarityName, RarityPhotoFront, RarityDescription, RarityCertificates, RarityEstimate, RarityEstimateLastDate, RarityProvenances, RarityCollection });

    }

    PhotoBack() {
        if (this.state.RarityPhotoBack) {
            return (
                <a href={url.url + this.state.RarityPhotoBack} target="_blank" rel="noopener noreferrer">
                <img className="img-fluid mb-3" src={url.url + this.state.RarityPhotoBack} alt={this.state.RarityName}>
                </img>
                </a>
            )
        }
        return null;

    }

    Certificates() {

        if (this.state.RarityCertificates.length > 0) {
            return (
                <div className="row">
                    <div className="col bg-light py-4 px-4 mb-3">
                        <h3 className="h3-small text-secondary font-weight-normal pb-3">Certificates</h3>
                        {this.state.RarityCertificates.map((rarityCertificates) =>
                            <div className="row  pb-3" key={rarityCertificates.idcertificate}>
                                <div className="col-4">
                                    <a href={url.url + rarityCertificates.certificate_file.file}>
                                        <img className="img-fluid" src={url.url + rarityCertificates.certificate_file.medium} alt="Certificate">
                                        </img>
                                    </a>
                                </div>
                                <div className="col-8">
                                    <a href={url.url + rarityCertificates.certificate_file.file}>
                                        <p className="text-primary mb-0">{rarityCertificates.certificate_number}</p>
                                    </a>
                                    <Link to={`/expert/${rarityCertificates.expert_id}`}>
                                        <p className="text-primary mb-0">{rarityCertificates.expert_name}</p>
                                    </Link>
                                    <p className="text-info">{rarityCertificates.certificate_date}</p>
                                </div>
                            </div>

                        )}
                    </div>
                </div>
            )
        }
        return null;

    }

    Estimate() {
        var RarityEstimateLastDate=""
        if(this.state.RarityEstimateLastDate){
          RarityEstimateLastDate=" in "+this.state.RarityEstimateLastDate
        }
        if (this.state.RarityEstimate) {
            return (
                <div className="row">
                    <div className="col bg-light py-4 px-4 mb-3">
                        <h3 className="h3-small text-secondary font-weight-normal pb-3">Prices</h3>
                        <p className="text-info mb-0">{this.state.RarityEstimate}{RarityEstimateLastDate}</p>
                    </div>
                </div>
            )
        }
        return null;

    }

    PartOfCollection() {

        if (this.state.RarityCollection) {
            return (
                <div className="row">
                    <div className="col bg-light py-4 px-4 mb-3">
                        <h3 className="h3-small text-secondary font-weight-normal pb-3">Part of Collection</h3>
                        <Link className="text-primary" to={this.state.baseCollectionUrl+this.state.RarityCollection.id}>
                            {this.state.RarityCollection.name}
                        </Link>
                    </div>
                </div>
            )
        }
        return null;

    }

    Collector(props) {
        if (!props[0] && !props[1]) {
            return (
                <p className="text-primary">{props[2]}</p>
            )
        }
        return (
            <p className="text-primary mb-0">{props[2]}</p>
        )
    }

    Price(props) {
        if (props[1]) {
            if (!props[0]) {
                return (
                    <p className="text-info">{props[1]}</p>
                )
            }
            return (
            <p className="text-info mb-0">{props[1]}</p>
            )
        }
        return null;
    }

    Date(props) {
        if (props) {
            return (
                <p className="text-info">{props}</p>
            )
        }
        return null;
    }

    Provenances() {
        if (this.state.RarityProvenances.length > 0) {
            return (
                <div className="row">
                    <div className="col bg-light py-4 px-4 mb-3">
                        <h3 className="h3-small text-secondary font-weight-normal pb-3">Provenances</h3>
                        {this.state.RarityProvenances.map((rarityProvenances) =>
                            <div className="row border-bottom py-3" key={rarityProvenances.collector.id}>
                                <div className="col">
                                    <Link to={this.state.baseCollectorUrl+rarityProvenances.collector.id}>
                                        {this.Collector([rarityProvenances.price_text, rarityProvenances.date, rarityProvenances.collector.name])}
                                        {this.Price([rarityProvenances.date, rarityProvenances.price_text])}
                                        {this.Date(rarityProvenances.date)}
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )
        }
        return null;

    }

    Bibliography() {

        if (this.state.RarityBibliography.length > 0) {
            return (
                <div className="row">
                    <div className="col bg-light py-4 px-4 mb-3">
                        <h3 className="h3-small text-secondary font-weight-normal pb-3">Bibliography</h3>
                        {this.state.RarityBibliography.map((rarityBibliography) => {
                            if (this.state.RarityBibliography.link !== "") {
                                return (
                                    <a href={rarityBibliography.link}>
                                        <p className="text-primary mb-0">{rarityBibliography.name}</p>
                                        <p className="text-info mb-0">{rarityBibliography.author}</p>
                                        <p className="text-secondary">{rarityBibliography.remarks}</p>
                                    </a>
                                )
                            }
                            return (
                                <div>
                                    <p className="text-primary mb-0">{rarityBibliography.name}</p>
                                    <p className="text-info mb-0">{rarityBibliography.author}</p>
                                    <p className="text-secondary">{rarityBibliography.remarks}</p>
                                </div>
                            )
                        }
                        )}
                    </div>
                </div>
            )
        }
        return null;

    }

    render() {
        return (
            <div className="container pt-3 pt-md-5 mb-5">
                <div className="row mb-3 mb-md-5">
                    <div className="col text-center">
                        <h2 className="text-primary font-weight-normal">{this.state.RarityName}</h2>
                    </div>
                </div>
                <div className="row align-items-top">
                    <div className="col-12 col-lg-9 text-left py-4 px-5 overflow-auto">
                        <a href={url.url + this.state.RarityPhotoFront} target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid mb-3" src={url.url + this.state.RarityPhotoFront} alt={this.state.RarityName}>
                        </img>
                        </a>
                        {this.PhotoBack()}
                    </div>
                    <div className="col-lg-3 offset-1 col-10 offset-lg-0 text-left">
                        <div className="row">
                            <div className="col bg-light py-4 px-4 mb-3">
                                <h3 className="h3-small text-secondary font-weight-normal pb-3">Description</h3>
                                <p className="text-secondary">{this.state.RarityDescription}</p>
                            </div>
                        </div>
                        {this.Estimate()}
                        {this.PartOfCollection()}
                        {this.Certificates()}
                        {this.Provenances()}
                        {this.Bibliography()}
                    </div>
                </div>
            </div>
        );
    }
}

export default RaritySingle;
