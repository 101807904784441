import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import url from './Url';

class CollectorList extends Component {
  state = {
    CollectorData: [],
    collector: '',
    baseCollectorUrl: '/collector/',
    intoductionText: ''
  }

  componentDidMount() {
    document.title = "Collectors - Museum of Philately"

    if(this.props.SubsiteSetting.id){
      document.title = `Collectors - ${this.props.SubsiteSetting.title} - Museum of Philately`
      this.setState({
          baseCollectorUrl: '/subsites/'+this.props.SubsiteSetting.slug+'/collector/',
      });
    }

    this.setState({ intoductionText: this.props.GlobalsData['collectors'] })

    const CollectorData = this.props.CollectorData;
    this.setState({ CollectorData });
  }

  handleChangeCollector = event => {
    this.setState({ collector: event.target.value })
  }

  render() {
    const CollectorData = this.state.CollectorData
    const collector = this.state.collector
    const filterDropdown = CollectorData.filter(function(result){
      return result.name.toLowerCase().includes(collector.toLowerCase());
    })
    let prevChar = ''
    const charList =[]
    let hasID = true

    return (
      <div className="container pt-3 pt-md-5 mb-5 container-collectorlist">
        <div className="row mb-3 mb-md-5 title-collectorlist">
          <div className="col text-center">
            <h2 className="text-primary font-weight-normal">Collectors</h2>
            <p dangerouslySetInnerHTML={{ __html: this.state.intoductionText }} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <form className="form-inline justify-content-center mb-5">
              <div className="form-group">
                <input onChange={this.handleChangeCollector} type="text" className="form-control form-control-sm" id="searchCollector" placeholder="Search for a collector"></input>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          {filterDropdown.map((collectorDetail) =>{
            if(prevChar!== collectorDetail.name.charAt(0).toUpperCase()){
                charList.push(collectorDetail.name.charAt(0).toUpperCase())
                hasID = true
            }else{
              hasID = false
            }
            prevChar = collectorDetail.name.charAt(0).toUpperCase();
            return(
            <div className="col-6 col-md-3 py-3" key={collectorDetail.idcollector}>
              {hasID && <div id={collectorDetail.name.charAt(0).toUpperCase()} className="anchor-char"></div>}
              <div className="row">
                <div className="col">
                  <Link to={this.state.baseCollectorUrl+collectorDetail.idcollector}>
                    <img className="collector-rounded-thumbnail img-thumbnail rounded-circle" src={url.url + collectorDetail.photo.medium} alt={'Picture of ' + collectorDetail.name}>
                    </img>
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p>
                    <Link to={this.state.baseCollectorUrl+collectorDetail.idcollector} className="text-primary">
                      {collectorDetail.name}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          )
        })}
        </div>
        <div className="char-nav">
          {charList.map((char) =>
            <a href={"#" + char} data-toggle="scroll-to" data-target={"#" + char} key={char}>{char}</a>
          )}
        </div>
      </div>
    );
  }
}

export default CollectorList;
