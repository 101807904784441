import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import url from './Url';

class SubsitesList extends Component {
  state = {
    SubsitesData: [],
    intoductionText: ''
  }

  componentDidMount() {
    document.title = "Satellite-Museum - Museum of Philately"

    const SubsitesData = this.props.SubsitesData;
    this.setState({ SubsitesData, intoductionText: this.props.GlobalsData['subsites'] });
  }

  

  componentDidUpdate(prevProps) {
    console.log('SubsitesList did update');
    if (this.props.SubsiteSetting.id) {
      console.log('A subsite was previously loaded');
      this.props.handleMuseumLoading();
    }
  }

  render() {

    return (
      <div className="container pt-3 pt-md-5 mb-5">
        <div className="row mb-3 mb-md-5">
          <div className="col text-center">
            <h2 className="text-primary font-weight-normal">Satellite-Museum</h2>
            <p dangerouslySetInnerHTML={{ __html: this.state.intoductionText }} />
          </div>
        </div>
        {this.state.SubsitesData.map((subsite) => {
            return (
                <div className="row align-items-center py-3 border-bottom" key={subsite.id}>
                <div className="col-2 offset-1">
                    <Link to={'/subsites/'+subsite.slug} onClick={(event) => this.props.handleSubsiteLoading(event, subsite.slug)}>
                    <img className="img-fluid collection-thumbnail" src={url.url + subsite.upload.medium} alt={'Picture of ' + subsite.title}>
                    </img>
                    </Link>
                </div>
                <div className="col-8 text-left">
                    <Link to={'/subsites/'+subsite.slug} onClick={(event) => this.props.handleSubsiteLoading(event, subsite.slug)}>
                    <h2 className="text-primary font-weight-normal h2-small">{subsite.title}</h2>
                    </Link>
                </div>
                </div>
            )
        }
        )}
      </div>
    );
  }
}
export default SubsitesList;
