import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import url from './Url';

class CollectionList extends Component {
  state = {
    CollectionData: [],
    UniqueCollectors: [],
    UniqueCountries: [],
    collector: 'all',
    country: 'all',
    baseCollectionUrl: '/collection/',
    baseCollectorUrl: '/collector/',
    intoductionText: ''
  }

  componentDidMount() {
    document.title = "Collections - Museum of Philately"
    if(this.props.SubsiteSetting.id){
      document.title = "Collections - "+this.props.SubsiteSetting.title+" - Museum of Philately"
      this.setState({ baseCollectionUrl: '/subsites/'+this.props.SubsiteSetting.slug+'/collection/', baseCollectorUrl: '/subsites/'+this.props.SubsiteSetting.slug+'/collector/' })
    }

    this.setState({ intoductionText: this.props.GlobalsData['collections'] })

    const CollectionData = this.props.CollectionData;


    let Collectors = [];
    CollectionData.forEach(element => {
      Collectors.push(element.collector.name)
    })
    const UniqueCollectors = Array.from(new Set(Collectors))

    let Countries = [];
    CollectionData.forEach(element => {
      Countries.push(element.main_category.name)
    })
    const UniqueCountries = Array.from(new Set(Countries))

    this.setState({ CollectionData, UniqueCollectors, UniqueCountries });
  }

  handleChangeCollector = event => {
    this.setState({ collector: event.target.value, country: 'all' })
  }

  handleChangeCountry = event => {
    this.setState({ country: event.target.value, collector: 'all' })
  }

  render() {
    const CollectionData = this.state.CollectionData
    const collector = this.state.collector
    const country = this.state.country

    const filterDropdown = CollectionData.filter(function (result) {
      if (collector === 'all') {
        if (country === 'all') {
          return CollectionData
        }

        return result.main_category.name === country;
      }
      return result.collector.name === collector;
    })

    return (
      <div className="container pt-3 pt-md-5 mb-5">
        <div className="row mb-3 mb-md-5">
          <div className="col text-center">
            <h2 className="text-primary font-weight-normal">Collections</h2>
            <p dangerouslySetInnerHTML={{ __html: this.state.intoductionText }} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <form className="form-inline justify-content-center mb-4 mx-n3">
              <div className="form-group mx-3 mb-3">
                <select className="form-control form-control-sm" value={this.state.collector} id="selectCollector" onChange={this.handleChangeCollector}>
                  <option value='all'>All Collectors</option>
                  {this.state.UniqueCollectors.sort(Intl.Collator().compare).map((uniqueCollector, index) =>
                    <option key={index} value={uniqueCollector}>
                      {uniqueCollector}
                    </option>
                  )}
                </select>
              </div>
              <div className="form-group mx-3 mb-3">
                <select className="form-control form-control-sm" value={this.state.country} id="selectCountry"  onChange={this.handleChangeCountry}>
                  <option value='all'>All Countries</option>
                  {this.state.UniqueCountries.sort(Intl.Collator().compare).map((uniqueCountries, index) =>
                    <option key={index} value={uniqueCountries}>
                      {uniqueCountries}
                    </option>
                  )}
                </select>
              </div>
            </form>
          </div>
        </div>
        {filterDropdown.map((collectionDetail) => {
          if (collectionDetail.type === 'collection') {
            return (
              <div className="row align-items-center py-3 border-bottom" key={collectionDetail.idcollection}>
                <div className="col-2 offset-1">
                  <Link to={this.state.baseCollectionUrl+collectionDetail.idcollection}>
                    <img className="img-fluid collection-thumbnail" src={url.url + collectionDetail.photo.medium} alt={'Picture of ' + collectionDetail.name}>
                    </img>
                  </Link>
                </div>
                <div className="col-8 text-left">
                  <Link to={this.state.baseCollectionUrl+collectionDetail.idcollection}>
                    <h2 className="text-primary font-weight-normal h2-small">{collectionDetail.name}</h2>
                  </Link>
                  <p>
                    <Link to={this.state.baseCollectorUrl+collectionDetail.collector.id}>
                      <span className="text-secondary">{collectionDetail.collector.name}</span>
                    </Link>
                    <span className="float-right text-info">{collectionDetail.pages.length + ' pages'}</span>
                  </p>
                </div>
              </div>
            )
          }
          return null;
        }
        )}
      </div>
    );
  }
}
export default CollectionList;
