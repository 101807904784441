import React, { Component } from "react";
import { withRouter } from "react-router-dom";
class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		const mainLocation = this.props.location.pathname.slice(0, this.props.location.pathname.lastIndexOf('/'))
		const mainPrevLocation = prevProps.location.pathname.slice(0, prevProps.location.pathname.lastIndexOf('/'))
		if ((this.props.location !== prevProps.location && this.props.location.pathname.split('/')[1]!=='collection') || (this.props.location.pathname.split('/')[1]==='collection' && mainLocation !== mainPrevLocation)) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'instant'
			});
		}
	}
	render() {
		return <React.Fragment />
	}
}

export default withRouter(ScrollToTop)
