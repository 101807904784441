import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './fa/css/all.css';
import url from './scripts/Url';
import ClipLoader from "react-spinners/ClipLoader";




import ScrollToTop from './scripts/ScrollToTop';
import Index from './pages/index';
import Subsite from './pages/subsite';
import Menu from './components/Menu';
import Footer from './components/Footer';
import CollectionList from './scripts/CollectionList';
import CollectionSingle from './scripts/CollectionSingle';
import CollectionPage from './scripts/CollectionPage';
import ExhibitionList from './scripts/ExhibitionList';
import CollectorList from './scripts/CollectorList';
import CollectorSingle from './scripts/CollectorSingle';
import ExpertList from './scripts/ExpertList';
import ExpertSingle from './scripts/ExpertSingle';
import RarityList from './scripts/RarityList';
import RaritySingle from './scripts/RaritySingle';
import CountryList from './scripts/CountryList';
import CountrySingle from './scripts/CountrySingle';
import Services from './scripts/Services';
import SubsitesList from './scripts/SubsitesList';

function isLight(color) {
  const r = parseInt(color.substr(1, 2), 16);
  const g = parseInt(color.substr(3, 2), 16);
  const b = parseInt(color.substr(5, 2), 16);
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luma > 40;
}

function HoverColor(color, amount) {
  if(isLight(color)) amount = -amount;

  const clamp = (val) => Math.min(Math.max(val, 0), 0xFF)
  const fill = (str) => ('00' + str).slice(-2)

  const num = parseInt(color.substr(1), 16)
  const red = clamp((num >> 16) + amount)
  const green = clamp(((num >> 8) & 0x00FF) + amount)
  const blue = clamp((num & 0x0000FF) + amount)
  return '#' + fill(red.toString(16)) + fill(green.toString(16)) + fill(blue.toString(16))
}
class App extends Component {
  state = {
    CollectionData: [],
    CollectorData: [],
    ExpertData: [],
    CountryData: [],
    RaritySectionsData: [],
    RarityData: [],
    SubsitesData: [],
    GlobalsData: [],
    SubsiteSetting: {},
    CollectionDataDownloaded: false,
    CollectorDataDownloaded: false,
    ExpertDataDownloaded: false,
    CountryDataDownloaded: false,
    RaritySectionsDataDownloaded: false,
    RarityDataDownloaded: false,
    SubsiteSettingDownloaded: false,
    SubsitesDataDownloaded: false,
    GlobalsDataDownloaded: false,
  }

  componentDidMount() {

    const pathArray = window.location.pathname.split('/');
    if (pathArray[1] === 'subsites' && pathArray[2] !== undefined) {
      this.handleSubsiteLoadingJsons(pathArray[2]);
    } else {
      this.handleMuseumLoadingJsons();
    }

    axios.get(`${url.url}jsons/countries.json`)
    .then(res => {
      const CountryData = res.data;
      this.setState({ CountryData, CountryDataDownloaded: true});
    }).catch(function (error) {
      console.log(error);
    });

    axios.get(`${url.url}jsons/subsites.json`)
    .then(res => {
      const SubsitesData = res.data;
      this.setState({ SubsitesData, SubsitesDataDownloaded: true});
    }).catch(function (error) {
      console.log(error);
    });

    axios.get(`${url.url}jsons/globals.json`)
    .then(res => {
      const GlobalsData = res.data;
      this.setState({ GlobalsData, GlobalsDataDownloaded: true});
    }).catch(function (error) {
      console.log(error);
    });

  }

  handleMuseumLoading = (event) => {
    console.log('Museum loading');
    this.handleMuseumLoadingJsons();
  }

  handleMuseumLoadingJsons = () => {

    this.setState({
      SubsiteSetting: {},
      SubsiteSettingDownloaded: true,
      CollectionDataDownloaded: false,
      CollectorDataDownloaded: false,
      ExpertDataDownloaded: false,
      RaritySectionsDataDownloaded: false,
      RarityDataDownloaded: false
    });

    axios.get(`${url.url}jsons/collections.json`)
    .then(res => {
      const CollectionData = res.data;
      this.setState({ CollectionData, CollectionDataDownloaded: true });
    }).catch(function (error) {
      console.log(error);
    });

    axios.get(`${url.url}jsons/collectors.json`)
    .then(res => {
      const CollectorData = res.data;
      this.setState({ CollectorData, CollectorDataDownloaded: true });
    }).catch(function (error) {
      console.log(error);
    });

    axios.get(`${url.url}jsons/items.json`)
    .then(res => {
      const RarityData = res.data.items;
      const RaritySectionsData = res.data.sections;
      this.setState({ RarityData, RaritySectionsData, RarityDataDownloaded: true, RaritySectionsDataDownloaded: true });
    }).catch(function (error) {
      console.log(error);
    });

    axios.get(`${url.url}jsons/experts.json`)
    .then(res => {
      const ExpertData = res.data;
      this.setState({ ExpertData, ExpertDataDownloaded: true});
    }).catch(function (error) {
      console.log(error);
    });
  }

  handleSubsiteLoading = (event, slug) => {
    console.log('Subsite loading '+slug);
    this.setState({
      SubsiteSettingDownloaded: false,
      CollectionDataDownloaded: false,
      CollectorDataDownloaded: false,
      ExpertDataDownloaded: false,
      RaritySectionsDataDownloaded: false,
      RarityDataDownloaded: false
    });
    this.handleSubsiteLoadingJsons(slug);
  }

  handleSubsiteLoadingJsons = (slug) => {
    axios.get(`${url.url}jsons/${slug}/settings.json`)
    .then(res => {
      const SubsiteSetting = res.data;
      this.setState({ SubsiteSetting, SubsiteSettingDownloaded: true });
    }).catch(function (error) {
      console.log(error);
    });

    axios.get(`${url.url}jsons/${slug}/collections.json`)
    .then(res => {
      const CollectionData = res.data;
      this.setState({ CollectionData, CollectionDataDownloaded: true });
    }).catch(function (error) {
      console.log(error);
    });

    axios.get(`${url.url}jsons/${slug}/collectors.json`)
    .then(res => {
      const CollectorData = res.data;
      this.setState({ CollectorData, CollectorDataDownloaded: true });
    }).catch(function (error) {
      console.log(error);
    });

    axios.get(`${url.url}jsons/${slug}/items.json`)
    .then(res => {
      const RarityData = res.data.items;
      const RaritySectionsData = res.data.sections;
      this.setState({ RarityData, RaritySectionsData, RarityDataDownloaded: true, RaritySectionsDataDownloaded: true });
    }).catch(function (error) {
      console.log(error);
    });

    axios.get(`${url.url}jsons/${slug}/experts.json`)
    .then(res => {
      const ExpertData = res.data;
      this.setState({ ExpertData, ExpertDataDownloaded: true});
    }).catch(function (error) {
      console.log(error);
    });
  }


  render() {
    if(this.state.CollectionDataDownloaded && this.state.CollectorDataDownloaded && this.state.ExpertDataDownloaded && this.state.CountryDataDownloaded && this.state.RaritySectionsDataDownloaded && this.state.RarityDataDownloaded && this.state.SubsiteSettingDownloaded && this.state.GlobalsDataDownloaded){
      let dynamicStyle = ``;
      if(this.state.SubsiteSetting.id){
        dynamicStyle = `
        :root {
          --primary-color: ${this.state.SubsiteSetting.color1};
          --secondary-color: ${this.state.SubsiteSetting.color2};
          --tertiary-color: ${this.state.SubsiteSetting.color3};
          --quaternary-color: ${this.state.SubsiteSetting.color4};
          --quinary-color: ${HoverColor(this.state.SubsiteSetting.color4, 50)};
          --senary-color: ${this.state.SubsiteSetting.color5};
          --septenary-color: ${HoverColor(this.state.SubsiteSetting.color2, 50)};
          --octonary-color: ${this.state.SubsiteSetting.color2}b3;
          --nonary-color: ${this.state.SubsiteSetting.color5}9c;
        }
        `;
      }
    return (
      <div className="App">
          <Helmet>
            <style>{dynamicStyle}</style>
          </Helmet>
          <BrowserRouter>
            <ScrollToTop />
            <Menu SubsiteSetting={this.state.SubsiteSetting} />
            <Switch>
              <Route path="/" component={Index} exact />
              <Route path="/collections" render={props=>
              (<CollectionList {...props} CollectionData={this.state.CollectionData} SubsiteSetting={this.state.SubsiteSetting} GlobalsData={this.state.GlobalsData} />)
              }/>
              <Route exact path="/collection/:id" render={props=>
              (<CollectionSingle {...props} CollectionData={this.state.CollectionData} SubsiteSetting={this.state.SubsiteSetting} />)
              }/>
              <Route exact path="/collection/:id/:page" render={props=>
              (<CollectionPage {...props} CollectionData={this.state.CollectionData} SubsiteSetting={this.state.SubsiteSetting} />)
              }/>
              {/*<Route path="/exhibitions" render={props=>
              (<ExhibitionList {...props} CollectionData={this.state.CollectionData} />)
              }/>*/}
              <Route path="/collectors"render={props=>
              (<CollectorList {...props} CollectorData={this.state.CollectorData} SubsiteSetting={this.state.SubsiteSetting} GlobalsData={this.state.GlobalsData} />)
              }/>
              <Route exact path="/collector/:id" render={props=>
              (<CollectorSingle {...props} CollectorData={this.state.CollectorData} SubsiteSetting={this.state.SubsiteSetting} />)
              }/>
              <Route path="/experts"render={props=>
              (<ExpertList {...props} ExpertData={this.state.ExpertData} SubsiteSetting={this.state.SubsiteSetting} GlobalsData={this.state.GlobalsData} />)
              }/>
              <Route exact path="/expert/:id" render={props=>
              (<ExpertSingle {...props} ExpertData={this.state.ExpertData} SubsiteSetting={this.state.SubsiteSetting} />)
              }/>
              <Route path="/rarities" render={props=>
              (<RarityList {...props} RaritySectionsData={this.state.RaritySectionsData} SubsiteSetting={this.state.SubsiteSetting} GlobalsData={this.state.GlobalsData} />)
              }/>
              <Route exact path="/rarity/:id" render={props=>
              (<RaritySingle {...props} RarityData={this.state.RarityData} SubsiteSetting={this.state.SubsiteSetting} />)
              }/>
              <Route path="/countries" render={props=>
              (<CountryList {...props} CountryData={this.state.CountryData} SubsiteSetting={this.state.SubsiteSetting} />)
              }/>
              <Route exact path="/country/:id" render={props=>
              (<CountrySingle {...props} CountryData={this.state.CountryData} SubsiteSetting={this.state.SubsiteSetting} />)
              }/>
              <Route exact path="/subsites/:subsite_slug" render={props=>
              (<Subsite {...props} SubsiteSetting={this.state.SubsiteSetting} GlobalsData={this.state.GlobalsData} />)
              }/>
              <Route exact path="/subsites/:subsite_slug/collections" render={props=>
              (<CollectionList {...props} CollectionData={this.state.CollectionData} SubsiteSetting={this.state.SubsiteSetting} GlobalsData={this.state.GlobalsData} />)
              }/>
              <Route exact path="/subsites/:subsite_slug/collection/:id" render={props=>
              (<CollectionSingle {...props} CollectionData={this.state.CollectionData} SubsiteSetting={this.state.SubsiteSetting} GlobalsData={this.state.GlobalsData} />)
              }/>
              <Route exact path="/subsites/:subsite_slug/collection/:id/:page" render={props=>
              (<CollectionPage {...props} CollectionData={this.state.CollectionData} SubsiteSetting={this.state.SubsiteSetting} GlobalsData={this.state.GlobalsData} />)
              }/>
              <Route exact path="/subsites/:subsite_slug/collectors" render={props=>
              (<CollectorList {...props} CollectorData={this.state.CollectorData} SubsiteSetting={this.state.SubsiteSetting} GlobalsData={this.state.GlobalsData} />)
              }/>
              <Route exact path="/subsites/:subsite_slug/collector/:id" render={props=>
              (<CollectorSingle {...props} CollectorData={this.state.CollectorData} SubsiteSetting={this.state.SubsiteSetting} GlobalsData={this.state.GlobalsData} />)
              }/>
              <Route path="/subsites/:subsite_slug/experts"render={props=>
              (<ExpertList {...props} ExpertData={this.state.ExpertData} SubsiteSetting={this.state.SubsiteSetting} GlobalsData={this.state.GlobalsData} />)
              }/>
              <Route exact path="/subsites/:subsite_slug/expert/:id" render={props=>
              (<ExpertSingle {...props} ExpertData={this.state.ExpertData} SubsiteSetting={this.state.SubsiteSetting} GlobalsData={this.state.GlobalsData} />)
              }/>
              <Route path="/subsites/:subsite_slug/rarities" render={props=>
              (<RarityList {...props} RaritySectionsData={this.state.RaritySectionsData} SubsiteSetting={this.state.SubsiteSetting} GlobalsData={this.state.GlobalsData} />)
              }/>
              <Route exact path="/subsites/:subsite_slug/rarity/:id" render={props=>
              (<RaritySingle {...props} RarityData={this.state.RarityData} SubsiteSetting={this.state.SubsiteSetting} GlobalsData={this.state.GlobalsData} />)
              }/>
              <Route path="/subsites" render={props=>
              (<SubsitesList {...props} SubsitesData={this.state.SubsitesData} SubsiteSetting={this.state.SubsiteSetting} handleSubsiteLoading={this.handleSubsiteLoading} handleMuseumLoading={this.handleMuseumLoading} GlobalsData={this.state.GlobalsData} />)
              }/>
              <Route path="/services" component={Services} />
            </Switch>
            <Footer SubsiteSetting={this.state.SubsiteSetting} />
          </BrowserRouter>
      </div >
    )}
    return (
      <div className="App">
        <ClipLoader
          size={50}
          color={"#d44430"}
        /><div className="text-primary">Loading Museum...</div>
      </div >
    )
  }
}

export default App;
