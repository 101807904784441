import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import banner from '../img/Banner-MoP-2021.jpg';
import bg from '../img/art-gallery-55.jpg';
import stampex from '../img/stampex_logo.jpeg';
import yt from '../img/image_video_MoP.jpg';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import url from '../scripts/Url';



class subsite extends Component {
    state = {
        backgroundImage: '',
        SubsiteSetting: {}
    }
    componentDidMount() {
        document.title = this.props.SubsiteSetting.title + " - Museum of Philately"
        this.setState({ backgroundImage: url.url + this.props.SubsiteSetting.cover.file, SubsiteSetting: this.props.SubsiteSetting});
    }
    render() {
        console.log(url.url + this.props.SubsiteSetting.cover.file);
        return (
            <div className="bg-main">
                <div className="container-fluid py-5 index-container bg-header-top" style={{ backgroundImage: `url(${this.state.backgroundImage})` }}>
                    <div className="row">
                        <div className="col-10 offset-1 col-xl-6 offset-xl-3 bg-primary-transparent mt-5 py-5 px-0">
                            <p className="text-uppercase index-sub px-5">Keep your collection alive</p>
                            <div className='d-flex flex-column flex-md-row justify-content-center align-items-center px-5'>
                                <img src={url.url + this.props.SubsiteSetting.upload.file} alt="logo" class="mb-3 pr-md-5 subsite-home-logo" />
                                <h1 className="subsite-index-title text-left">{this.state.SubsiteSetting.title}</h1>
                            </div>
                            <p className="text-light mt-3 px-5">{this.state.SubsiteSetting.description}</p>
                        </div>
                    </div>
                    <div className="row pt-5">
                        <div className="col">
                            <Link to={'/subsites/'+this.state.SubsiteSetting.slug+'/collections'} role="button" className="btn btn-secondary mx-2"><strong>View the collections</strong></Link>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default subsite;
