import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap';
import logo from '../img/MoP_logo.svg';
import url from '../scripts/Url';

class Menu extends Component {
    render() {
        const pathArray = window.location.pathname.split('/');
        if (pathArray[1] === 'subsites' && pathArray[2] !== undefined) {
            return (
                <Navbar collapseOnSelect   bg="light" expand="lg" className="fixed-top shadow-sm p-0">
                    <Navbar.Brand as={Link} to={`/subsites/${this.props.SubsiteSetting.slug}`} className="mb-0 p-0 h1">
                        <img src={url.url + this.props.SubsiteSetting.upload.file} alt="logo" height="70" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="ml-auto mr-4 custom-toggler" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto mr-4" as="ul">
                            <Nav.Item as="li">
                                <Nav.Link eventKey="1" as={NavLink} to={`/subsites/${pathArray[2]}/collections`}><i className="fas fa-book pr-1"></i> Collections</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link eventKey="3" as={NavLink} to={`/subsites/${pathArray[2]}/rarities`}><i className="fas fa-gem pr-1"></i> Rarities</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link eventKey="4"  as={NavLink} to={`/subsites/${pathArray[2]}/collectors`}><i className="fas fa-user-tie pr-1"></i> Collectors</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link eventKey="5"  as={NavLink} to={`/subsites/${pathArray[2]}/experts`}><i className="fas fa-user-tie pr-1"></i> Experts</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link eventKey="6" href={'/'} ><i className="fas fa-building-columns pr-1"></i> Museum</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            );
        }
        return (
            <Navbar collapseOnSelect   bg="light" expand="lg" className="fixed-top shadow-sm p-0">
                <Navbar.Brand as={Link} to={`/`} className="mb-0 p-0 h1">
                    <img src={logo} alt="logo" height="70" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="ml-auto mr-4 custom-toggler" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto mr-4" as="ul">
                        <Nav.Item as="li">
                            <Nav.Link eventKey="1" as={NavLink} to="/collections"><i className="fas fa-book pr-1"></i> Collections</Nav.Link>
                        </Nav.Item>
                        {/*}<Nav.Item as="li">
                            <Nav.Link eventKey="2" as={NavLink} to="/exhibitions"><i className="fas fa-store-alt"></i> Exhibitions</Nav.Link>
                        </Nav.Item>*/}
                        <Nav.Item as="li">
                            <Nav.Link eventKey="3" as={NavLink} to="/rarities"><i className="fas fa-gem pr-1"></i> Rarities</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link eventKey="4"  as={NavLink} to="/collectors"><i className="fas fa-user-tie pr-1"></i> Collectors</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link eventKey="5"  as={NavLink} to="/experts"><i className="fas fa-user-tie pr-1"></i> Experts</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <a target="_blank" href="https://blog.museumofphilately.com" className="nav-link"><i className="fas fa-pen-nib pr-1"></i> Blog</a>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link eventKey="7" as={NavLink} to="/services"><i className="fas fa-info-circle pr-1"></i> Services</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link eventKey="8" as={NavLink} to="/subsites"><i className="fas fa-satellite pr-1"></i> Satellite-Museum</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
    
}

export default Menu;
