import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import url from './Url';

class CollectionPage extends Component {

    Previous() {
        if (this.props.match.params.page > 1) {
            return (
                <Link className="mx-3" to={`${this.state.baseCollectionUrl}${this.props.match.params.id}/${parseInt(this.props.match.params.page) - 1}`}>
                    <p className="text-info d-inline"> <i className="fas fa-long-arrow-alt-left"></i> Previous</p>
                </Link>
            )
        }
        return  (
            <Link className="mx-3 disabled" to={`${this.state.baseCollectionUrl}${this.props.match.params.id}/${parseInt(this.props.match.params.page) - 1}`} onClick={ (event) => event.preventDefault() }>
                <p className="text-info d-inline"> <i className="fas fa-long-arrow-alt-left"></i> Previous</p>
            </Link>
        );
    }

    Next() {
        if (this.props.match.params.page < this.state.CollectionPages.length) {
            return (
                <Link className="text-info mx-3" to={`${this.state.baseCollectionUrl}${this.props.match.params.id}/${parseInt(this.props.match.params.page) + 1}`}>
                    <p className="text-info d-inline">Next <i className="fas fa-long-arrow-alt-right"></i></p>
                </Link>
            )
        }
        return (
            <Link className="text-info mx-3 disabled" to={`${this.state.baseCollectionUrl}${this.props.match.params.id}/${parseInt(this.props.match.params.page) + 1}`}  onClick={ (event) => event.preventDefault() }>
                <p className="text-info d-inline">Next <i className="fas fa-long-arrow-alt-right"></i></p>
            </Link>
        )
    }

    PreviousChevron() {
        if (this.props.match.params.page > 1) {
            return (
                <Link className="text-dark position-absolute collection-prev-chevron" to={`${this.state.baseCollectionUrl}${this.props.match.params.id}/${parseInt(this.props.match.params.page) - 1}`}>
                    <i className="fas fa-chevron-left"></i>
                </Link>
            )
        }
    }

    NextChevron() {
        if (this.props.match.params.page < this.state.CollectionPages.length) {
            return (
                <Link className="text-dark position-absolute collection-next-chevron" to={`${this.state.baseCollectionUrl}${this.props.match.params.id}/${parseInt(this.props.match.params.page) + 1}`}>
                    <i className="fas fa-chevron-right"></i>
                </Link>
            )
        }
    }

    PreviousPage() {
      if (this.props.match.params.page > 1) {
            return (
              <img className="img-fluid m-0 img-collection-prev" src={url.url + this.state.CollectionPrevPage} alt={this.state.CollectionPages.name}>
              </img>
            )
      }
      return(
        <span className="img-collection-prev"></span>
      )
    }

    NextPage() {
        if (this.props.match.params.page < this.state.CollectionPages.length) {
              return (
                <img className="img-fluid m-0 img-collection-next" src={url.url + this.state.CollectionNextPage} alt={this.state.CollectionPages.name}>
                </img>
              )
        }
        return(
          <span className="img-collection-next"></span>
        )
    }

    state = {
        CollectionName: '',
        CollectionPages: [],
        CollectorCollections: [],
        CollectionIndex: [],
        baseCollectionUrl: '/collection/',
        baseCollectorUrl: '/collector/',
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.match.params.page !== this.props.match.params.page) {

            const CollectionData = this.props.CollectionData;
            const idcollection = CollectionData.findIndex(x => x.idcollection === parseInt(this.props.match.params.id));

            const CollectionName = CollectionData[idcollection].name
            const CollectionPages = CollectionData[idcollection].pages
            const CollectionPage = CollectionData[idcollection].pages[this.props.match.params.page - 1].photo.file

            if (CollectionData[idcollection].pages[this.props.match.params.page - 2]){
              const CollectionPrevPage = CollectionData[idcollection].pages[this.props.match.params.page - 2].photo.file
              this.setState({ CollectionPrevPage });
            }
            if(CollectionData[idcollection].pages[this.props.match.params.page]){
              const CollectionNextPage = CollectionData[idcollection].pages[this.props.match.params.page].photo.file
              this.setState({ CollectionNextPage });
            }
            const CollectionIndex = CollectionData[idcollection].index

            document.title = `${CollectionName} - Page ${this.props.match.params.page} - Museum of Philately`

            if(this.props.SubsiteSetting.id){
                document.title = `${CollectionName} - Page ${this.props.match.params.page} - ${this.props.SubsiteSetting.title} - Museum of Philately`;
            }

            this.setState({ CollectionIndex, CollectionName, CollectionPage, CollectionPages });
        }
    }

    componentDidMount() {

        const CollectionData = this.props.CollectionData;
        const idcollection = CollectionData.findIndex(x => x.idcollection === parseInt(this.props.match.params.id));

        const CollectionName = CollectionData[idcollection].name
        const CollectionMainCat = CollectionData[idcollection].main_category.name
        const CollectionPages = CollectionData[idcollection].pages
        const CollectionPage = CollectionData[idcollection].pages[this.props.match.params.page - 1].photo.file
        if (CollectionData[idcollection].pages[this.props.match.params.page - 2]){
          const CollectionPrevPage = CollectionData[idcollection].pages[this.props.match.params.page - 2].photo.file
          this.setState({ CollectionPrevPage });
        }
        if(CollectionData[idcollection].pages[this.props.match.params.page]){
          const CollectionNextPage = CollectionData[idcollection].pages[this.props.match.params.page].photo.file
          this.setState({ CollectionNextPage });
        }
        const CollectionIndex = CollectionData[idcollection].index
        const CollectionCollectorID = CollectionData[idcollection].collector.id
        const CollectionCollectorPhoto = CollectionData[idcollection].collector.photo.thumbnail
        const CollectionCollectorName = CollectionData[idcollection].collector.name

        document.title = `${CollectionName} - Page ${this.props.match.params.page} - Museum of Philately`

        if(this.props.SubsiteSetting.id){
            document.title = `${CollectionName} - Page ${this.props.match.params.page} - ${this.props.SubsiteSetting.title} - Museum of Philately`
            this.setState({
                baseCollectionUrl: '/subsites/'+this.props.SubsiteSetting.slug+'/collection/',
                baseCollectorUrl: '/subsites/'+this.props.SubsiteSetting.slug+'/collector/'
            });
          }

        this.setState({ CollectionIndex, CollectionName, CollectionMainCat, CollectionPage, CollectionPages, CollectionCollectorID, CollectionCollectorPhoto, CollectionCollectorName });
    }

    render() {
        return (
            <div>
              <div className="container pt-3 pt-md-5 mb-5">
                <div className="row mb-4">
                        <h2 className="col-12 text-center mb-4 text-primary font-weight-normal text-uppercase"><strong>
                          {this.state.CollectionMainCat}
                        </strong></h2>
                    <div className="col-8 col-lg-4 offset-2 text-info text-lg-left mb-4 mb-lg-0">{this.state.CollectionName}</div>
                    <div className="col-lg-4 text-info text-lg-right">
                      <Link to={this.state.baseCollectorUrl+this.state.CollectionCollectorID} className="text-info">
                        <img className="collector-rounded-thumbnail-small rounded-circle mr-2" src={url.url + this.state.CollectionCollectorPhoto} alt={'Picture of ' + this.state.CollectionCollectorName}></img> <strong>by {this.state.CollectionCollectorName}</strong>
                      </Link>
                    </div>
                </div>
                <div className="row align-items-top position-relative">
                    <div className="col-2 d-flex justify-content-end align-items-center">{this.PreviousChevron()}</div>
                    <div className="col-8 text-center img-collection-page-large">
                        <a href={url.url + this.state.CollectionPage} target="_blank" rel="noopener noreferrer">
                            <img className="img-fluid m-0" src={url.url + this.state.CollectionPage} alt={this.state.CollectionPages.name}>
                            </img>
                        </a>
                    </div>
                    <div className="col-2 d-flex justify-content-start align-items-center">{this.NextChevron()}</div>
                    <div className="col-12 d-flex justify-content-between position-absolute img-collection-prevnext-container">
                      {this.PreviousPage()}
                      {this.NextPage()}
                    </div>
                </div>
                </div>
                <div className="container-fluid nav-collection-page bg-light">
                <div className="row">
                    <div className="col text-center">
                        {this.Previous()}
                        <span className="text-primary mx-5">{this.props.match.params.page}/{this.state.CollectionPages.length}</span>
                        {this.Next()}
                    </div>
                </div>
            </div>
            </div>
        );
    }
}
export default CollectionPage;
