import React, { Component } from 'react';
import banner from '../img/workshop-4515582_1920.jpg';
import bg from '../img/art-gallery-55.jpg';
import restoration from '../img/restoration.jpg';
import exhibition from '../img/Screenshot-2020-08-26-at-10.35.27.png';
import expertising from '../img/Philatelist-Gabriel-stamp-view_2.jpg';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import logoDF from '../img/df_logo.svg'





class Services extends Component {

    componentDidMount() {
        document.title = "Services - Museum of Philately"
    }

    render() {
        return (
            <div className="bg-main">
                <div className="container-fluid pt-3 pb-5 pt-md-5 index-container bg-header" style={{ backgroundImage: `url(${banner})` }}>
                    <div className="row">
                        <div className="col-10 offset-1 col-xl-6 offset-xl-3 bg-primary-transparent mt-5 py-5 px-0">
                            <p className="text-uppercase index-sub px-5">One step closer to an award winning collection</p>
                            <h1 className="index-title text-left">Services</h1>
                        </div>
                    </div>
                    <div className="row pt-5">
                        <div className="col">
                            <AnchorLink offset='70' href="#contact" role="button" className="btn btn-secondary mx-2"><strong>Contact us</strong></AnchorLink>
                        </div>
                    </div>
                </div>
                <div className="container py-5" style={{ backgroundImage: `url(${bg})` }}>
                    <div className="row">
                        <div className="col-10 offset-1 bg-services p-4" style={{ backgroundImage: `url(${restoration})` }}>
                            <div className="row">
                                <div className="col-10 offset-1">
                                    <h1 className="index-title-title">Philatelic Paper Restoration</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-10 offset-1 mb-5 p-4 shadow-lg text-left bg-white">
                            <h2 className="index-small-title mb-5">Restore your philatelic treasures</h2>
                            <p className="text-secondary">Collectors and Exhibitors will appreciate that there are times when a key item in their collection or display needs some form of restoration or repair, whether it has become damaged or soiled through an accident, poor storage, or through the passage of time.</p>
                            <p className="text-secondary">Professional paper restoration preserves document integrity and ensures value is maintained. We can offer a specialised restoration services for postal material, from surface cleaning (using dry, chemical-free methods), crease smoothing (with PTFE implements), and reinforcement / tear repairs (with acid-free, archival materials).</p>
                            <p className="text-secondary">The restoration work is undertaken and will improve the appearance and longevity of damaged covers, and help your collection look its best and last for many years to come.</p>
                            <p className="text-secondary">We also provide advice on ‘preventative conservation’ which is focused upon your custodianship and how best to store, display and handle your collection.</p>
                            <p className="text-secondary mb-5">Jasmine Smith entered the specialist field of postal history covers following many years working in libraries and archives, where she learnt how to repair documents and books, and through further professional studies and graduating in Preservation and Library Science, she mastered the techniques of repair and preservation of books before progressing to philatelic collectables. Her philatelic restoration service is focused upon correcting physical damage – surface cleaning, repairing tears, filling holes, and smoothing creases, and removing and correcting previous repairs, perhaps made with office supplies and poorly-matched paper, as well as ensuring that any old exhibit mountings are professionally removed to safeguard against damage and any likely deterioration.</p>
                            <AnchorLink offset='70' href='#contact' className="black-links">Contact Us</AnchorLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-10 offset-1 bg-services bg-services-2 p-4" style={{ backgroundImage: `url(${exhibition})` }}>
                            <div className="row">
                                <div className="col-10 offset-1">
                                    <h1 className="index-title-title">Exhibiting Services & Mounting Your Collection</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-10 offset-1 mb-5 p-4 shadow-lg text-left bg-white">
                            <h2 className="index-small-title mb-5">Prepare your collection for exhibition</h2>
                            <p className="text-secondary">Whether you are mounting your collection for your own pleasure and future resale, or you are involved with displaying, exhibiting and competing, we can provide professional advice and consultancy services to ensure you reach and exceed your expectations. Our Exhibiting Consultant Bill Schultz specialises in providing ‘Mounting’ and ‘Exhibiting’ services, to ensure you maximise the presentation of your collection or exhibit, and ensuring you gain the optimum value now and in years to come.</p>
                            <p className="text-secondary">Bill has been a philatelist and postal historian for over 60 years, and is currently an accredited APS Chief Judge and an enthusiastic exhibitor, having produced over 50 different exhibits in recent years. He is an Assistant Treasurer of the United States Philatelic Classics Society, board member and life member of the American Association of Philatelic Exhibitors, and a lecturer for the APS summer seminar series, as well as a guest speaker of the New York Collectors Club. Bill is also the past president of the Pennsylvania Postal History Society, as well as still writing a monthly column for the American Stamp Dealer and Collector publication entitled “The Facets of Exhibiting”.</p>
                            <AnchorLink offset='70' href='#contact' className="black-links">Contact Us</AnchorLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Services;
