import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import url from './Url';


class RarityList extends Component {
  state = {
    RaritySections: [],
    UniqueProvenances: [],
    UniqueCollections: [],
    UniqueCountries: [],
    collection: 'all',
    provenance: 'all',
    country: 'all',
    baseRarityUrl: '/rarity/',
    intoductionText: ''
  }

  componentDidMount() {
    document.title = "Rarities - Museum of Philately"

    if(this.props.SubsiteSetting.id){
      document.title = `Rarities - ${this.props.SubsiteSetting.title} - Museum of Philately`
      this.setState({
          baseRarityUrl: '/subsites/'+this.props.SubsiteSetting.slug+'/rarity/'
      });
    }

    this.setState({ intoductionText: this.props.GlobalsData['rarities'] })
        const RaritySections = this.props.RaritySectionsData;

        let Collections = [];
        RaritySections.forEach(section => {
          section.items.forEach(item => {
            if (item.collection) {
              return (
                Collections.push(item.collection.name)
              )
            }
            return null;
          })
        })
        const UniqueCollections = Array.from(new Set(Collections))

        let Provenances = [];
        RaritySections.forEach(section => {
          section.items.forEach(item => {
            return (
              item.provenances.forEach(element => {
                Provenances.push(element.collector.name)
              })
            )
          })
        })
        const UniqueProvenances = Array.from(new Set(Provenances))

        let Countries = [];
        RaritySections.forEach(item => {
          Countries.push(item.name)
        })
        const UniqueCountries = Array.from(new Set(Countries))


        this.setState({ RaritySections, UniqueCollections, UniqueProvenances, UniqueCountries });
  }


  handleChangeProvenances = event => {
    this.setState({ provenance: event.target.value, collection: 'all', country: 'all' })
  }

  handleChangeCollections = event => {
    this.setState({ collection: event.target.value, provenance: 'all', country: 'all' })
  }

  handleChangeCountries = event => {
    this.setState({ country: event.target.value, collection: 'all', provenance: 'all' })
  }

  render() {
    const RaritySections = this.state.RaritySections
    const collection = this.state.collection
    const provenance = this.state.provenance
    const country = this.state.country
    const filterDropdown = [];

    Object.keys(RaritySections).forEach(sectionKey => {
      const section=RaritySections[sectionKey];
      const newItems = section.items.filter(function (item) {
        if (collection === 'all') {
          if (provenance === 'all') {
            if (country === 'all') {
              return true
            }
            return (section.name === country);
          }
          let has_provenance=false;
          if(item.provenances !== undefined){
            item.provenances.forEach(itemProvenance => {
              if(itemProvenance.collector.name===provenance) {
                has_provenance=true;
              }
            });
          }
          return has_provenance;
        }
        return (item.collection && item.collection!==undefined && item.collection.name === collection);
      });
      if(newItems.length>0){
        if(filterDropdown[sectionKey]===undefined) filterDropdown[sectionKey]=[];
        filterDropdown[sectionKey]['name']=RaritySections[sectionKey]['name'];
        filterDropdown[sectionKey]['items']=newItems;
      }

    });


    return (
      <div>

      <div className="container-fluid pt-3 pt-md-5" >
        <div className="row mb-3 mb-md-5">
          <div className="col text-center">
            <h2 className="text-primary font-weight-normal">Rarities</h2>
            <p dangerouslySetInnerHTML={{ __html: this.state.intoductionText }} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <form className="form-inline justify-content-center mb-4 mx-n3">
              <div className="form-group mx-3 mb-3">
                <select className="form-control form-control-sm" value={this.state.provenance} id="selectProvenances" onChange={this.handleChangeProvenances}>
                  <option value='all'>All provenances</option>
                  {this.state.UniqueProvenances.sort(Intl.Collator().compare).map((uniqueProvenance, index) =>
                    <option key={index} value={uniqueProvenance}>
                      {uniqueProvenance}
                    </option>
                  )}
                </select>
              </div>
              <div className="form-group mx-3 mb-3">
                <select className="form-control form-control-sm" value={this.state.collection} id="selectCollection" onChange={this.handleChangeCollections}>
                  <option value='all'>All collections</option>
                  {this.state.UniqueCollections.sort(Intl.Collator().compare).map((uniqueCollection, index) =>
                    <option key={index} value={uniqueCollection}>
                      {uniqueCollection}
                    </option>
                  )}
                </select>
              </div>
              <div className="form-group mx-3 mb-3">
                <select className="form-control form-control-sm" value={this.state.country} id="selectCountry" onChange={this.handleChangeCountries}>
                  <option value='all'>All countries</option>
                  {this.state.UniqueCountries.sort(Intl.Collator().compare).map((uniqueCountry, index) =>
                    <option key={index} value={uniqueCountry}>
                      {uniqueCountry}
                    </option>
                  )}
                </select>
              </div>
            </form>
          </div>
        </div>
        </div>
        <div className="container mb-5" >
          {filterDropdown.map((sectionDetail, index) =>
            <div className="mb-5" key={index}>
              <div className="row">
                <div className="col-12 py-3 px-4 bg-light">
                  <h2 className="text-secondary text-left font-weight-normal h2-small">{sectionDetail.name}</h2>
                </div>
              </div>
              {sectionDetail.items.map((sectionItems) =>
                <div className="row align-items-center py-3 border-bottom" key={sectionItems.iditem}>
                  <div className="col-2 offset-1">
                    <Link to={this.state.baseRarityUrl+sectionItems.iditem}>
                      <img className="img-fluid collection-thumbnail" src={url.url + sectionItems.photo_front.medium} alt={'Picture of ' + sectionItems.name}>
                      </img>
                    </Link>
                  </div>
                  <div className="col-8 text-left">
                    <Link to={this.state.baseRarityUrl+sectionItems.iditem}>
                      <h2 className="text-primary font-weight-normal h2-small">{sectionItems.name}</h2>
                    </Link>
                    <p>
                      <span className="text-secondary">{sectionItems.subtitle}</span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div >
      </div >
    );
  }
}

export default RarityList;
