import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import url from './Url';

class CountrySingle extends Component {

  state = {
    CountryName: '',
    CountryItems: [],
    CountryCollections: []
  }

  componentDidMount() {

        const CountryData = this.props.CountryData;
        const idcountry = parseInt(this.props.match.params.id);

        const CountryName = CountryData[idcountry].name
        const CountryItems = CountryData[idcountry].items
        const CountryCollections = CountryData[idcountry].collections

        document.title = `${CountryName} - Museum of Philately`

        this.setState({ CountryName, CountryItems, CountryCollections });
  }

  Items() {
    if (this.state.CountryItems.length>0) {
      return (
        <div className="mb-5">
          <div className="row py-3 px-4 bg-light">
            <div className="col">
              <h2 className="text-secondary text-left font-weight-normal h2-small">Rarities</h2>
            </div>
          </div>
          {
            this.state.CountryItems.map((countryItems) =>
              <div className="row align-items-center py-3 border-bottom" key={countryItems.id}>
                <div className="col-2 offset-1">
                  <Link to={`/rarity/${countryItems.id}`}>
                    <img className="img-fluid collection-thumbnail" src={url.url + countryItems.photo.medium} alt={'Picture of ' + countryItems.name}>
                    </img>
                  </Link>
                </div>
                <div className="col-8 text-left">
                  <Link to={`/rarity/${countryItems.id}`}>
                    <h2 className="text-primary font-weight-normal h2-small">{countryItems.name}</h2>
                  </Link>
                </div>
              </div>
            )
          }
        </div>
      )
    }
    return null;
}

Collections() {
  if (this.state.CountryCollections.length>0) {
    return (
      <div className="mb-5">
        <div className="row py-3 px-4 bg-light">
        <div className="col">
        <h2 className="text-secondary text-left font-weight-normal h2-small">Collections</h2>
        </div>
      </div>
      {this.state.CountryCollections.map((countryCollections) =>
        <div className="row align-items-center py-3 border-bottom" key={countryCollections.id}>
          <div className="col-2 offset-1">
            <Link to={`/collection/${countryCollections.id}`}>
              <img className="img-fluid collection-thumbnail" src={url.url + countryCollections.photo.medium} alt={'Picture of ' + countryCollections.name}>
              </img>
            </Link>
          </div>
          <div className="col-8 text-left">
            <Link to={`/collection/${countryCollections.id}`}>
              <h2 className="text-primary font-weight-normal h2-small">{countryCollections.name}</h2>
            </Link>
          </div>
        </div>
      )}
      </div>
    )
  }
  return null;

}

  render() {
    return (
      <div className="container pt-3 pt-md-5">
        <div className="row mb-3 mb-md-5">
          <div className="col text-center">
            <h2 className="text-primary font-weight-normal">{this.state.CountryName}</h2>
          </div>
        </div>
        {this.Items()}
        {this.Collections()}
      </div>
    );
  }
}
export default CountrySingle;
