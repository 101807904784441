import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../img/MoP_logo_no_baseline.svg';
import award from '../img/pts_award_2022.jpg';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ContactUs from '../components/ContactUs';



class Footer extends Component {
    render()
    {
        if(this.props.SubsiteSetting.id){
            return (
                <>
                        <footer className="container-fluid pt-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-4 mb-4"><h2>Museum of Philately</h2>
                                      <div className="row">
                                        <div className="col-6">
                                        <a href="/"><img src={logo} alt="logo" width="100px" /></a>
                                        </div>
                                        <div className="col-6">
                                          <img src={award} alt="The Philatelic Trader's Society Awards Runner Up" width="100px" />
                                        </div>
                                        <p className="my-2"><small>The Museum of Philately is a unique digital platform that captures and preserves award winning collectors, collections and rarities, as well as providing the facility to track provenance and pricing information.
                            </small></p>
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-4 mb-4"><h2>Browse Museum</h2>
                                        <ul className="navbar-nav my-2">
                                            <li className="nav-item px-4">
                                                <NavLink to={'/subsites/'+this.props.SubsiteSetting.slug+'/collections'} className="nav-link"><strong>Collections</strong></NavLink>
                                            </li>
                                            <li className="nav-item px-4">
                                                <NavLink to={'/subsites/'+this.props.SubsiteSetting.slug+'/rarities'} className="nav-link"><strong>Rarities</strong></NavLink>
                                            </li>
                                            <li className="nav-item px-4">
                                                <NavLink to={'/subsites/'+this.props.SubsiteSetting.slug+'/collectors'} className="nav-link"><strong>Collectors</strong></NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-4 mb-4"><h2>Follow Us</h2>
                                        <div className="mb-4">
                                        <a href="https://www.instagram.com/museumofphilately/" rel="noopener noreferrer" target="_blank" className="p-0 social mr-2 my-2 text-center">
                                            <i className="fab fa-instagram m-0"></i>
                                        </a>
                                        <a href="https://twitter.com/ofphilately" rel="noopener noreferrer" target="_blank" className="p-0 social mr-2 my-2 text-center">
                                            <i className="fab fa-twitter m-0"></i>
                                        </a>
                                        <a href="https://www.youtube.com/channel/UCmUKsUHOgKcwHPIltyPfmaQ" rel="noopener noreferrer" target="_blank" className="p-0 social mr-2 my-2 text-center">
                                          <i className="fab fa-youtube m-0"></i>
                                        </a>
                                        </div>
                                        <a href="http://eepurl.com/hqJU9n" rel="noopener noreferrer" target="_blank" className="my-2 btn btn-outline-secondary">Subscribe to the newsletter</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mt-5 mb-3">
                                        <p>© {new Date().getFullYear()} Museum of Philately, All Rights Reserved.
                </p>
                                    </div>
                                </div>
                            </div>
                        </footer>
                        </>
                        );
        } else {
            return (
                <>
                        <ContactUs />
                        <footer className="container-fluid pt-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-4 mb-4"><h2>Museum of Philately</h2>
                                      <div className="row">
                                        <div className="col-6">
                                        <a href="/"><img src={logo} alt="logo" width="100px" /></a>
                                        </div>
                                        <div className="col-6">
                                          <img src={award} alt="The Philatelic Trader's Society Awards Runner Up" width="100px" />
                                        </div>
                                        <p className="my-2"><small>The Museum of Philately is a unique digital platform that captures and preserves award winning collectors, collections and rarities, as well as providing the facility to track provenance and pricing information.
                            </small></p>
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-4 mb-4"><h2>Browse Museum</h2>
                                        <ul className="navbar-nav my-2">
                                            <li className="nav-item px-4">
                                                <NavLink to="/collections" className="nav-link"><strong>Collections</strong></NavLink>
                                            </li>
                                            <li className="nav-item px-4">
                                                <NavLink to="/rarities" className="nav-link"><strong>Rarities</strong></NavLink>
                                            </li>
                                            <li className="nav-item px-4">
                                                <NavLink to="/collectors" className="nav-link"><strong>Collectors</strong></NavLink>
                                            </li>
                                            <li className="nav-item px-4">
                                                <NavLink to="/experts" className="nav-link"><strong>Experts</strong></NavLink>
                                            </li>
                                            <li className="nav-item px-4">
                                                <a href="https://blog.museumofphilately.com" className="nav-link"><strong>Blog</strong></a>
                                            </li>
                                            <li className="nav-item px-4">
                                                <NavLink to="/services" className="nav-link"><strong>Services</strong></NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-4 mb-4"><h2>Follow Us</h2>
                                        <div className="mb-4">
                                        <a href="https://www.instagram.com/museumofphilately/" rel="noopener noreferrer" target="_blank" className="p-0 social mr-2 my-2 text-center">
                                            <i className="fab fa-instagram m-0"></i>
                                        </a>
                                        <a href="https://twitter.com/ofphilately" rel="noopener noreferrer" target="_blank" className="p-0 social mr-2 my-2 text-center">
                                            <i className="fab fa-twitter m-0"></i>
                                        </a>
                                        <a href="https://www.youtube.com/channel/UCmUKsUHOgKcwHPIltyPfmaQ" rel="noopener noreferrer" target="_blank" className="p-0 social mr-2 my-2 text-center">
                                          <i className="fab fa-youtube m-0"></i>
                                        </a>
                                        </div>
                                        <a href="http://eepurl.com/hqJU9n" rel="noopener noreferrer" target="_blank" className="my-2 btn btn-outline-secondary">Subscribe to the newsletter</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mt-5 mb-3">
                                        <p>© {new Date().getFullYear()} Museum of Philately, All Rights Reserved.
                </p>
                                    </div>
                                </div>
                            </div>
                        </footer>
                        </>
                        );
        }
     
    }
}

export default Footer;
